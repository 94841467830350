import Head from 'next/head';
import {serverSideTranslations} from 'next-i18next/serverSideTranslations';
import MainLayout from '../layouts/MainLayout';
import Script from 'next/script';
import axios from '../lib/axios';
import {useRouter} from 'next/router';
import {GetStaticPropsContext} from 'next';
import {ContentContainer} from '@interfaces/content-container';
import {LocaleEnum} from '@enums/locale';
import {APIRequestConfig} from '@enums/request-config';
import HeadMeta from '@components/Header/HeadMeta';

interface HomePageProps {
  data: ContentContainer;
}
export default function Homeapge({data}: HomePageProps) {
  const router = useRouter();
  const locale = router.locale as unknown as LocaleEnum;
  return (
    <MainLayout>
      <HeadMeta
        title={data[`seo_page_title_${locale}`]}
        description={data[`seo_description_${locale}`]}
        keywords={data[`seo_keywords_${locale}`]}
        canonical_url={data[`canonical_url_${locale}`]}
      />
      <div className="">
        <div className="container-xxl">
          {data.contents.map((content, index) => {
            return (
              <section
                className={`${content.div_container} ${content.columns}`}
                key={index}
              >
                {content.columns === 3 && (
                  <div
                    className={`${content['column_div_1']} 3`}
                    dangerouslySetInnerHTML={{
                      __html: content[`column_div_1_${locale}`]!,
                    }}
                  ></div>
                )}

                {content.columns >= 2 && (
                  <div
                    className={`${content['column_div_2']} 2`}
                    dangerouslySetInnerHTML={{
                      __html: content[`column_div_2_${locale}`]!,
                    }}
                  ></div>
                )}
                {content.columns >= 1 && (
                  <div
                    className={`${content['column_div_3']} 1`}
                    dangerouslySetInnerHTML={{
                      __html: content[`column_div_3_${locale}`],
                    }}
                  ></div>
                )}
              </section>
            );
          })}
        </div>
      </div>
    </MainLayout>
  );
}

export async function getStaticProps({locale}: GetStaticPropsContext) {
  const response = await axios.get<any>('pages/homepage', {
    headers: {[APIRequestConfig.X_LOCALE]: locale!},
  });
  return {
    props: {
      data: response.data.data,
      ...(await serverSideTranslations(locale!, ['common'])),
    },
  };
}
