import Head from 'next/head';

const HeadMeta = ({
  title,
  description,
  keywords,
  canonical_url,
}: {
  title?: string;
  description?: string;
  keywords?: string;
  canonical_url?: string;
}) => {
  return (
    <Head>
      <title>{title}</title>
      {!!description && (
        <meta name={'description'} content={description} key={'description'} />
      )}
      {!!keywords && (
        <meta name={'keywords'} content={keywords} key={'keywords'} />
      )}
      {!!keywords && (
        <meta name={'keywords'} content={keywords} key={'keywords'} />
      )}
      {!!canonical_url && <link rel="canonical" href={canonical_url} />}
    </Head>
  );
};

export default HeadMeta;
